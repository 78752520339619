<template>
	<div class="home">
		<div class="jpm_defaults_wapper">

			<div class="jpm_logo_top">
				<div><img src="@/assets/image/logo.svg" /></div>
				<div>
					<!-- <P class="loginbt" @click="changeLanguage">Asia/<span>Thailand</span></P> -->
					<div class="navright" @click="changeLanguage">
						<span>{{ texType == 0 ? $t("gensuixitong") : $t("jiantizhongwen") }} </span>
					</div>
				</div>
			</div>


			<div class="jpm_logo">
				<div class="jpm_logo_middle">
					<p>{{ $t("wmjsb") }}</p>
					<p>{{ $t("wmjs") }}</p>
				</div>
				<div class="top_pic">
					<div @click="changeRecharge"><img src="@/assets/image/cunru.jpg" /><!--{{ $t('cunru') }}--></div>
					<div @click="changeWithdraw"><img src="@/assets/image/zhuanchu.jpg" /><!--{{ $t('zhuanchu') }}--></div>
					<div @click="changekefu"><img src="@/assets/image/kefu.jpg" /><!--{{ $t("kefu") }}--></div>
				</div>
			</div>

			<!-- <div class="jpm_logoa"><img src="@/assets/image/jpmlogol.svg" /></div> -->
			<!-- <div class="jpm_content"> -->
			<!-- <p>{{ $t("womenshishui") }}</p>
				{{ $t("wmjs") }}{{ $t("wmjsa") }}{{ $t("wmjsc") }} -->


			<!-- </div> -->
		</div>

		<!-- <swi-pe></swi-pe> -->

		<!-- <div class="jpm_defaults_wapper_a_b">
			<p>{{ $t("yejs") }}</p>
			{{ $t("yejsa") }}
			<p>{{ $t("yejsb") }}</p>{{ $t("yejsc") }}
			<div>{{ $t("yejsd") }}</div>
		</div> -->

		<lt-list></lt-list>

		<!-- <div class="middle_font"><p>{{ $t("wmjsc") }}</p><p>{{ $t("wmjsa") }}</p></div> -->

		<div class="prize-news-wrap">
			<div class="sub-title-contact">
				<div class="sub-title">
					<span>{{ $t("jiaoyiyonghu") }}</span>
					<span>{{ $t("jiaoyipinglei") }}</span>
					<span>{{ $t("danlunhuoli") }}</span>
					<span>{{ $t("jiaoyiriqi") }}</span>
				</div>
			</div>
			<div class="prize-news">
				<vue-seamless-scroll class="vux-marquee prize-marquee" :class-option="classOption" :data="infoList">
					<ul class="vux-marquee-box">
						<li class="prize-marquee-item" v-for="(item, index) in infoList" :key="index">
							<div class="prize-marquee-item-contact">
								<span class="name">{{ item.nickname }}</span>
								<span class="type">{{ item.game_name }}</span>
								<span class="money">{{ item.profit }}</span>
								<span class="date">{{time}}</span>
							</div>
						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>

		<tab-bar></tab-bar>
	</div>
</template>

<script>
	// @ is an alias to /src
	import TabBar from "@/components/tabbar/TabBar.vue";
	import LtList from "@/components/ltlist/LtList.vue";
	// import SwiPe from "@/components/swipe/SwiPe.vue";
	import homeApi from "@/api/home";
	import vueSeamlessScroll from "vue-seamless-scroll/src";
	import storeAction from "@/store/typed-actions";
	export default {
		name: "Home",
		components: {
			TabBar,
			// SwiPe,
			LtList,
			vueSeamlessScroll,
		},
		computed: {
			classOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					// openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
			money() {
				console.log(this.$store.state.money);
				return this.$store.state.money;
			},
			activeList() {
				console.log(this.$store.state.activeList);
				return this.$store.state.activeList;
			},
		},
		data() {
			return {
				showList: false,
				infoList: [],
				isShow: true,
				texType: localStorage.getItem("localetype"),
				time: ''
			};
		},
		methods: {
			changeRecharge() {
				this.$router.push({
					path: "/Recharge",
				});
			},
			changeWithdraw() {
				this.$router.push({
					path: "/Withdraw",
				});
			},
			changekefu() {
				this.$router.push({
					path: "/kefu",
				});
			},
			changeLanguage() {
				this.$router.push({
					path: "/language",
				});
			},
			activechange(active) {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 1000,
				});
				storeAction.setCurrentUseractiveList(active);
				storeAction.setsessionStorage("activeList", active);
				console.log(active);
			},
			info() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				homeApi
					.heart("", "")
					.then((data) => {
						console.log(data.data);
						this.infoList = data.data.hit_notice;
						this.$toast.clear();
					})
					.catch((err) => {
						// alert(err)
						// this.$toast.clear();
						this.$toast.fail(err);
					});
			},
			changeNews() {
				this.$router.push({
					path: "/moreNews",
				});
			},
			// changelogin() {
			// 	this.$router.push({
			// 		path: "/lottery",
			// 	});
			// },
		},
		mounted() {

		},
		created() {
			let myDate = new Date();
			let time = myDate.toLocaleDateString();
			this.time = time.slice(5)
			this.info();
		},
	};
</script>
<style lang="less" scoped>
	.home {
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.middle_font {
		padding: 0 0.6rem 0.65rem 0.6rem;
		text-align: center;
		background: url(../assets/image/info_bg.jpg);
		background-size: 100% auto;

		p:nth-child(1) {
			font-size: 0.34rem;
			color: #000;
			font-family: 'nstb';
			margin-bottom: 4px;
		}
	}

	.prize-news-wrap {
		padding-bottom: 1.4rem;
		background: none;
	}

	.sub-title-contact {
		// background: rgb(255,255,255);
		// background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		width: calc(100% - 1.2rem);
		margin: 0 auto;
		// background: #0073cf;
		border-radius: 6px;
	}

	.sub-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 0.26rem;
		font-family: 'nstb';
		color: #ccc;
		padding: 0 0 0.2rem 0;
		width: 100%;
		margin: 0 auto;
	}

	.sub-title span {
		width: 25%;
	}

	.prize-news {
		height: 200px;
		width: calc(100% - 1.2rem);
		margin: 0 auto;
	}

	.prize-marquee {
		height: 200px !important;
		font-size: 14px;
	}

	.vux-marquee {
		width: 100%;
		overflow: hidden;
		height: 200px;
	}

	.vux-marquee-box {
		padding: 0;
		margin: 0;
		width: 100%;
		height: auto;
	}

	.prize-marquee-item {
		padding: 0.2rem 0;
		border-radius: 6px;
		background-color: #f6f7f9;
		margin-bottom: 5px;
	}

	.prize-marquee-item-contact {
		width: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			width: 25%;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.name {
			color: #000;
			font-size: 0.28rem;
			font-family: 'nstb';
		}

		.type {
			color: #999;
			font-size: 0.28rem;
		}

		.money {
			color: #000;
			font-size: 0.28rem;
			font-family: 'nstb';
		}

		.date {
			color: #ccc;
			font-size: 0.26rem;
		}
	}

	.content {
		position: absolute;
		width: 100%;
		background: #1a243f;
		top: 1.18rem;
		z-index: 12;
	}

	.line-content {
		background-color: #0ab45b;
	}

	.line-content .line-item {
		height: 1rem;
		font-size: 0.32rem;
		padding: 0 0.4rem 0 0.2rem;
		display: flex;
		align-items: center;
		color: #fff;
	}

	.line-item :after {
		content: " ";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 1px;
		-webkit-transform-origin: 0 100%;
		transform-origin: 0 100%;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.line-item .icon {
		margin-left: auto;
		width: 0.6rem;
		height: 0.6rem;
	}

	.angleRotestow {
		transform: rotate(180deg);
		transition: transform 0.3s, -webkit-transform 0.3s;
	}

	.icon-arrow-bottoms {
		transform: rotate(0);
		transition: transform 0.3s;
	}

	.jpm_defaults_wapper {
		background: #fff;
		position: relative;
	}



	.jpm_logo_top {
		padding: 0 0.6rem;
		position: fixed;
		top: 0;
		height: 50px;
		z-index: 9999;
		width: 100%;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;

		img {
			width: 180px;
		}

		.loginbt {
			color: #ccc;

			span {
				font-family: 'nstb';
				color: #aaa;
			}
		}
	}

	.jpm_logo {
		width: calc(100% - 1.2rem);
		margin: 51px auto 0 auto;
		background: #e31837;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		// background: rgb(1,33,105);
		// background: linear-gradient(160deg, rgba(1,33,105,1) 50%, rgba(227,24,55,1) 100%);

		.top_pic {
			position: absolute;
			bottom: 0.4rem;
			right: 0.6rem;
			margin: 0;
			box-sizing: border-box;
			text-align: left;
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				// border: .03rem solid rgba(96, 160, 240, .5);
				padding: 0;
				font-size: 0.28rem;
				text-align: center;
				background: #fff;
				padding: 5px;
				margin-left: 6px;
				border-radius: 4px;
			}

			img {
				width: 16px;
				display: block;
				margin: 0 auto 0 auto;
			}
		}


		.jpm_logo_middle {
			padding: 0.6rem 0.6rem 2.8rem 0.6rem;
			text-align: right;
			color: #fff;
			background: url(../assets/image/home_banner.jpg);
			background-size: 72% auto;
			background-position: bottom left;
			background-repeat: no-repeat;

			p:nth-child(2) {
				font-size: 0.26rem;
				line-height: 0.4rem;
			}

			p:nth-child(1) {
				color: #fff;
				font-family: 'nstb';
				font-size: 0.34rem;
				margin-bottom: 2px;
			}

		}

	}

	.navright {
		color: #ccc;
		font-size: 0.27rem;
		margin-top: 10px;
		// font-family: 'nstb';
	}

	.jpm_logoa {
		height: 50%;
		position: absolute;
		top: -30px;
		right: -20px;

		img {
			width: 100%;
		}
	}

	.jpm_content {
		width: calc(90% - 1.2rem);
		margin-left: .6rem;
		padding-bottom: 1rem;
		text-align: left;
		color: #60a0f0;
		font-size: 11px;
		z-index: 999;

		p {
			font-family: 'nstb';
			font-size: 20px;
			color: #fff;
			margin-bottom: 8px;
		}
	}

	.jpm_defaults_wapper_a {
		width: calc(90% - 1.2rem);
		margin-left: .6rem;
		margin: 1.2rem auto 0 auto;
		height: auto;
		font-size: 11px;
		text-align: left;

		img {
			width: 60%;
			margin-bottom: 10px;
			display: block;
		}
	}

	.jpm_defaults_wapper_a_b {
		width: 100%;
		margin: 0 auto 0 auto;
		padding: 1rem .6rem 1rem .6rem;
		height: auto;
		font-size: 11px;
		text-align: left;
		background: #fff;

		p {
			font-family: 'nstb';
			font-size: 11px;
			color: #333;
			display: inline-block;
		}

		div {
			border: .02rem solid #ececec;
			color: #333;
			border-radius: 5px;
			margin-top: 10px;
			padding: 10px 12px;
			font-family: 'nstb';
		}
	}
</style>